<template>
  <div class="account">
    <div class="navbar-box">
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
          <span class="text">Muzi Xiansen</span>
          <span class="vip">SVIP</span>
        </template>
        <template #right>
          <div class="detail" @click="to_vip_information">Detail</div>
        </template>
      </van-nav-bar>
    </div>

    <div class="content div-bg">
      <van-image width="100%" :src="require('./img/img.png')" />
      <div class="bg"></div>
    </div>

    <VipTabbar name="vip-square" />
  </div>
</template>

<script>
import VipTabbar from "@/components/vip-tabbar";
export default {
  components: { VipTabbar },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ path: "/my" });
    },
    to_vip_information() {
      this.$router.push({ path: "/vip/information" });
    },
    to_vip_rewards() {
      this.$router.push({ path: "/vip/rewards" });
    },
    to_vip_commission() {
      this.$router.push({ path: "/vip/commission" });
    },
    to_vip_history() {
      this.$router.push({ path: "/vip/history" });
    },
    to_vip_rules() {
      this.$router.push({ path: "/vip/rules" });
    },
  },
};
</script>

<style lang="scss" scoped>
.account {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__title {
      overflow: initial;
    }

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .text {
      font-weight: 600;
      font-size: 2.25rem;
      color: #000000;
      margin-right: 1rem;
    }

    .vip {
      border-radius: 3.125rem;
      padding: 0.125rem 1rem;
      font-size: 1.375rem;
      color: #ffffff;
      line-height: 1.875rem;
      background-color: #e5285b;
    }

    .detail {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      font-size: 1.5rem;
      color: #3362ff;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      border: 1px solid #3362ff;
      line-height: 1;
    }
  }

  .content {
    padding: 0 0 7.5rem;

    .bg {
      .bg-1 {
        width: 100%;
        height: 33.75rem;
        opacity: 0.5;
        margin-bottom: 1.25rem;
      }
      .bg-2 {
        width: 100%;
        height: 31.25rem;
        opacity: 0.5;
        margin-bottom: 1.25rem;
      }
      .bg-3 {
        width: 100%;
        height: 26.25rem;
        opacity: 0.5;
        margin-bottom: 3.125rem;
      }
      .bg-4 {
        width: 100%;
        height: 16.25rem;
        background-color: yellow;
        opacity: 0.5;
        margin-bottom: 1.25rem;
      }
      .bg-5 {
        width: 100%;
        height: 6.25rem;
        opacity: 0.5;
      }
    }
  }

  .btn {
    margin-top: 1.25rem;
    position: sticky;
    bottom: 0;
    z-index: 999;
    line-height: 0;
  }
}
</style>